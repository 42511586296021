import React, { useState, useEffect } from "react";
import axios from "axios";
import WorkBookImageOne from "../../images/bookImages/Book1.webp";

const FORM_ID = "7070892";
const API_KEY = "orcj11jDI14VkybpDd91mA";

const resources = [
  {
    title: "The Seeker",
    imgSrc: WorkBookImageOne,
    text: "Download",
    downloadLink: "https://drive.usercontent.google.com/u/0/uc?id=1DuInxcmRE0fQ4324sXypstYLhTkEfR8W&export=download",
  },
  // {
  //   title: "The Seeker Book Launch",
  //   imgSrc: WorkBookImageOne,
  //   text: "Watch Now",
  //   downloadLink: "https://youtu.be/u9cQNtEnmzM",
  // },
];

const ResourcesWorkbook = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";
  }, [isModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDownloadClick = (url, index) => {
    if (index === 0) {
      setDownloadUrl(url);
      setIsModalOpen(true);
    } else {
      window.open(url, "_blank");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phone } = formData;

    if (!name || !email || !phone) {
      alert("Please fill in all fields.");
      return;
    }

    const subscriberData = {
      api_key: API_KEY,
      email,
      first_name: name,
      fields: { phone_number: phone },
    };

    try {
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/${FORM_ID}/subscribe`,
        subscriberData
      );

      if (response.data.subscription) {
        alert("Your Download will start soon");
        window.location.href = downloadUrl;
        setIsModalOpen(false);
      } else {
        alert("Download failed. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please check your details and try again.");
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-6 py-12 relative">
      <h2 className="text-center text-xl font-semibold tracking-wide uppercase text-gray-800">
        Guides, Worksheets, Videos and Books
      </h2>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-8">
        {resources.map((resource, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            <img
              src={resource.imgSrc}
              alt={resource.title}
              className="w-full max-w-[250px] shadow-lg rounded-md transition-transform duration-300 hover:scale-105 hover:shadow-xl"
            />
            <h3 className="mt-4 text-sm lg:text-lg font-semibold italic text-gray-900">
              {resource.title}
            </h3>
            <button
              onClick={() => handleDownloadClick(resource.downloadLink, index)}
              className="mt-2 text-xs lg:text-sm font-semibold text-[#181349] flex items-center gap-1 hover:underline"
            >
              ↓ {resource.text}
            </button>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 z-50 relative">
            <h3 className="text-lg font-semibold text-gray-800 text-center">
              Enter Your Details
            </h3>
            <form onSubmit={handleSubmit} className="mt-4">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md mb-3"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md mb-3"
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Your Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md mb-3"
                required
              />
              <button
                type="submit"
                className="w-full bg-[#181349] text-white py-2 rounded-md hover:bg-[#141033] transition duration-300"
              >
                Download Now
              </button>
              <button
                type="button"
                className="w-full mt-2 text-gray-600 hover:underline text-sm"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourcesWorkbook;
