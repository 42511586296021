import React from "react";
import ResourcesHero from "./ResourcesHero";
import ResourcesWorkbook from "./ResourcesWorkbook";
import ResourceSpeakers from "./ResourceSpeakers";

const Resources = () => {
  return (
    <>
      <ResourcesHero />
      <ResourcesWorkbook />
      {/* <ResourceSpeakers /> */}
    </>
  );
};

export default Resources;
